<template>
  <!-- Brand add form dialog -->
  <el-dialog
    title="Нийлүүлэгч үүсгэх"
    :close-on-click-modal="false"
    :visible.sync="visibleSupplierDialog"
    @close="closeModal"
    width="500">
    <el-form label-position="top" :model="supplierForm" :rules="supplierRules" ref="supplierForm">
      <el-form-item>
          <el-col :span="11">
            <el-form-item label="Монгол нэр" prop="supplier_monName">
              <el-input v-model="supplierForm.supplier_monName" clearable placeholder="Говь ХХК ..."></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-form-item label="Англи нэр" prop="supplier_engName">
              <el-input v-model="supplierForm.supplier_engName" clearable placeholder="Gobi LLC ..."></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
      <el-form-item prop="supplier_regno" label="Регистрийн дугаар">
        <el-input v-model="supplierForm.supplier_regno" placeholder="304987"></el-input>
      </el-form-item>
      <el-row :gutter="20" type="flex" align="middle">
        <el-col :span="12">
          <el-form-item prop="phone" label="Утасны дугаар 1">
            <el-input v-model="supplierForm.phone" placeholder="99999999"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="phone2" label="Утасны дугаар 2">
            <el-input v-model="supplierForm.phone2" placeholder="99999999"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="address" label="Хаяг">
        <el-input v-model="supplierForm.address" type="textarea" rows="3" placeholder="ХУД 3-р хороо Гоёо"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Гарах</el-button>
      <el-button type="success" @click="onPressNewSupplier('supplierForm')">
        Үүсгэх
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import services from '../../helpers/services'

export default {
  name: 'supplierDialog',
  props: {
    visibleSupplierDialog: {
      require: true,
      default: false,
      type: Boolean
    },
    alertReporter: {
      type: Function
    },
    getSuppliers: {
      type: Function
    },
    closeModal: {
      type: Function
    }
  },
  data () {
    return {
      supplierForm: {
        supplier_monName: '',
        supplier_engName: '',
        supplier_regno: '',
        phone: '',
        phone2: '',
        address: '',
        location: '-71.34, 41.12'
      },
      supplierRules: {
        supplier_monName: [
          { required: true, message: 'Монгол нэр оруулна уу', trigger: 'blur' }
        ],
        supplier_engName: [
          { required: true, message: 'Англи нэр оруулна уу', trigger: 'blur' }
        ],
        supplier_regno: [
          { required: true, message: 'Регистрийн дугаараа бөглөнө үү', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу', trigger: 'blur' },
          { min: 6, max: 8, message: 'Утасны дугаараа бүрэн оруулна уу', trigger: 'blur' }
        ],
        phone2: [
          { required: true, message: 'Утасны дугаараа оруулна уу', trigger: 'blur' },
          { min: 6, max: 8, message: 'Утасны дугаараа бүрэн оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Хаягаа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onPressNewSupplier (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          services.addSupplier(this.supplierForm).then(data => {
            this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
            if (data.status === 'success') {
              this.getSuppliers(data.data.supplier_id)
              this.$refs[formName].resetFields()
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
